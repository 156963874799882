import { createMuiTheme } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: blue
  },
  overrides: {
    //colorPrimary: red,
    MuiDrawer: {
        paper: {
          //background: '#18202c',
        },
    },
    MuiAppBar:{
      primary: red
    },
    MuiTableCell: {
      root: {
        padding: 7
      }
    }
  },
});

export default theme;