import axios from 'axios';
import { logout } from './users/actions';
import reduxStore from './store';

const instance = axios.create({
  baseURL: (process.env.NODE_ENV !== 'production') ? 'http://localhost:8080/api' : 'https://kitapi.ezahtevek.eu/api' ,
});

const {dispatch} = reduxStore; // direct access to redux store.

// instance.interceptors.request.use(
//   async (config) => {
//     //get jwt token from redux store
//     //to bo veljalo za vse axios klice
//     //axios.defaults.headers.common['Authorization'] = window.localStorage.getItem('token');
//     debugger;
//     const token = window.localStorage.getItem('token');
//     if (token) {
//       config.headers["Authorization"] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

instance.interceptors.response.use(null, (error) => {
  if(error && error.response){
    if (error.response.status === 401) {
      dispatch(logout());
    }else if(error.response.status === 503){
      //props.history.push('/login') //we will redirect user into 503 page   
    }
  }

  if (!error.status) {
    // network error
    return Promise.reject(error);
  }
  
  

  return Promise.reject(error);
})

export default instance;