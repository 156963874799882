import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import getRoutes from '../routes';
import { withStyles } from '@material-ui/core/styles';
import Main from './main';
import SideNav from './sideNav';
import AppBar from './AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme2 from '../theme';

const styles = theme => ({
  root: {
    //flexGrow: 1,
    //zIndex: 1,
    //overflow: 'hidden',
    //position: 'relative',
    //display: 'flex'
  },
});

class Root extends Component {

  render() {
    const { classes, user } = this.props;

    return (
      <MuiThemeProvider theme={theme2}>
        <div className={classes.root}>
          {user.auth &&
            <React.Fragment>
              <AppBar />
              {/* <SideNav /> */}
            </React.Fragment>
          }
          <Main>
            { getRoutes() }
          </Main>
        </div>
      </MuiThemeProvider>
    );
  }
}

Root.propTypes = {
  classes: PropTypes.object.isRequired,
  //users: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  user: state.user.data,
});

//export default DragDropContext(HTML5Backend)(Rezervacija);

export default compose(
    withStyles(styles),
    connect(mapStateToProps, {}),
  )(Root);

// export default withRouter(
//   compose(
//     withStyles(styles),
//     connect(mapStateToProps, {}),
//   )(Root)
// );
