import React from 'react';
import { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import CarIcon from '@material-ui/icons/DirectionsCar'
import AssignmentLate from '@material-ui/icons/AssignmentLate'
import StorageIcon from '@material-ui/icons/Storage';
import NoteAdd from '@material-ui/icons/NoteAdd';
import SubjectIcon from '@material-ui/icons/Subject';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import {List, ListSubheader, ListItemIcon, ListItem, ListItemText  } from '@material-ui/core';

class NavigationList extends Component{
  
render(){
  const { user } = this.props;

  return (
    <List 
      className="nav-link"
      subheader={<ListSubheader component="div">Menu</ListSubheader>}>
      {/* <ListItem button component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Domov" />
      </ListItem> */}

      { 
        user.role === "admin" ? 
          (
          <ListItem button component={Link} to="/users/all">  
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Seznam uporabnikov" />
          </ListItem>) : null
      }

      {
        user.role === "admin" ? 
        (
            <ListItem button component={Link} to="/seznamopravil">
              <ListItemIcon>
                <AllInboxIcon />
              </ListItemIcon>
              <ListItemText primary="Seznam opravil" />
            </ListItem>
        ) : null
      }

      {
        user.role === "raziskovalec" || user.role === "admin"  ? 
        (
            <ListItem button component={Link} to="/opravilaedit">
              <ListItemIcon>
                <NoteAdd />
              </ListItemIcon>
              <ListItemText primary="Dodaj opravilo" />
            </ListItem>
        ) : null
      }

    </List>
  );
  }
}

const mapStateToProps = state => ({
  user: state.user.data,
});

export default connect(mapStateToProps, {}) (NavigationList);